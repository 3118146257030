import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/app/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/shop-fb/ProductCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/shop-fb/Seeding.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/accounts.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/box-empty.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/cart.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/coffee-footer.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/core-value-1.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/core-value-2.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/core-value-3.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/core-value-4.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/core-value-5.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/default_image.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/default-avatar.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/empty-cart.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/gift.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/header-img.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/heart-footer.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/heart.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/help.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/hero-blur.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/logout.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/mock-qr.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/money.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/sale.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/seeding.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/tiktok.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/voucher-green.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/voucher.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/wallet-small.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/wallet.png");
;
import(/* webpackMode: "eager" */ "/app/src/components/atom/motion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/LogoAnimation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","AccordionItem","AccordionTrigger","AccordionContent"] */ "/app/src/components/ui/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel","CarouselContent","CarouselItem","CarouselPrevious","CarouselNext"] */ "/app/src/components/ui/carousel.tsx");
