"use client";

import { LATEST_ORDERS_SUBSCRIPTION } from "@/lib/graphql";
import { formatCurrency } from "@/lib/helper";
import { Order } from "@/types";
import { OnDataOptions, useSubscription } from "@apollo/client";
import { X } from "@phosphor-icons/react";
import { groupBy } from "lodash";
import Image from "next/image";
import { toast } from "sonner";

const Seeding = () => {
  useSubscription(LATEST_ORDERS_SUBSCRIPTION, {
    onData({ data }: OnDataOptions<{ orderCreated: Order }>) {
      toast.custom(
        (toastId) => {
          const dt = data.data?.orderCreated;
          const groupedRequirements = groupBy(
            dt?.requirements,
            "cloneModelDetail.name"
          );
          const fistRequirement = Object.values(
            groupedRequirements
          )[0][0] as any;
          const mappedRest = Object.values(groupedRequirements)
            .slice(1)
            .map((reqs) => {
              const first = reqs[0] as any;
              return `${first.quantity}${first.cloneModelDetail.name}`;
            });
          const totalPrice = dt?.totalPrice;

          return (
            <div className="flex gap-2 items-center" key={toastId}>
              <Image
                width={48}
                height={48}
                src={fistRequirement?.cloneModelDetail?.iconUrl}
                alt="adv"
                className="h-12 object-cover rounded-2xl"
              />
              <div className="flex-1">
                <span className="text-neutural-strong">
                  {dt?.receiverEmail} đã mua
                </span>{" "}
                <span className="font-semibold text-primary-content">
                  {fistRequirement?.quantity || 1}{" "}
                  {fistRequirement?.cloneModelDetail?.name}
                </span>
                <div className="text-sm text-neutural-strong">
                  {mappedRest.length > 0 && (
                    <span>{mappedRest.join(", ")}</span>
                  )}
                  <span> Tổng tiền: {formatCurrency(totalPrice || 0)}</span>
                </div>
              </div>
              <div className="me-2">
                <X
                  aria-label="Đóng"
                  className="cursor-pointer"
                  onClick={() => toast.dismiss(toastId)}
                />
              </div>
            </div>
          );
        },
        {
          position: "top-center",
          id: Math.random().toString(),
          className:
            "bg-white rounded-2xl bg-opacity-70 backdrop-blur-[10px] w-[460px] p-1 border border-gray-50 shadow-2xl",
        }
      );
    },
  });

  return undefined;
};

export default Seeding;
